<template>
  <div :style="styles.renderingAreaProvider" ref="youtubeBox" data-youtube="1">
    <YouTube :src="videoId" :vars="vars" ref="youtube" @ready="onReady" @state-change="onStateChange" style="width: 100%; height: 0; position: relative;"/>
  </div>
</template>
<script>
import { config } from '@/constants'
import YouTube from 'vue3-youtube'

export default {
  components: { YouTube },
  props: [
   'id',
  ],
  data() {
    return {
      styles: {
        renderingAreaProvider: {
          background: "#f0f0f0",
          height: 0,
          paddingBottom: "calc(56.25%)",
          position: "relative",
          width: "100%"
        },
        iframe: {
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%"
        }
      },
      videoId: (this.$props.id ? this.$props.id : config.VIDEO_ID),
      width: 640,
      height: 360,
      vars: {
        autoplay: 1,
        // controls: 0,
        rel: 0,
      },
      watched: (() => {
        return !!localStorage.getItem('movieFlag')
      })(),
    }
  },
  mounted() {
    this.$emit('watched', this.watched);
    let cnt = 0
    const this2 = this
    const interval = setInterval(() => {
      if (!this2.$refs.youtubeBox) clearInterval(interval)
      const iframe = this2.$refs.youtubeBox.querySelector('iframe')

      if (cnt > 100 || iframe) {
        try {
          if (!iframe.parentNode) clearInterval(interval)
          for (const key in this2.styles.iframe) {
            iframe.style[key] = this2.styles.iframe[key]
            iframe.parentNode.style[key] = this2.styles.iframe[key]
          }
          clearInterval(interval)
        } catch(e) {
          console.log(e)
          clearInterval(interval)
        }
      }
      cnt++
    }, 100)
  },
  methods: {
    initVideo() {
      const youtube = this.$refs.youtube
      youtube.seekTo(0)
      youtube.stopVideo()
    },
    onReady() {
      this.initVideo()
      this.$emit('ready', true)
    },
    onStateChange(state) {
      if (state.data === 0) {
        this.initVideo()
        // localStorageに視聴済みフラグを保存
        localStorage.setItem('movieFlag', '1')
        this.$emit('watched', true);
      }
    },
  },
}
</script>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
